var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['log-section', { open: _vm.isOpen }]},[_c('div',{staticClass:"card-log"},[_c('div',{staticClass:"graph-buttons-resources"},[_c('button',{class:['btn-graph-resources', _vm.showPipelines ? 'btn-actual' : 'btn-disabled'],attrs:{"type":"button"},on:{"click":function () {
            this$1.$emit('showPipelines', true);
            this$1.$emit('fetchPipeline');
          }}},[_vm._v(" Pipeline ")]),_c('button',{class:['btn-graph-resources', _vm.showPipelines ? 'btn-disabled' : 'btn-actual'],attrs:{"type":"button"},on:{"click":function () {
            this$1.$emit('showPipelines', false);
            this$1.$emit('fetchShir');
          }}},[_vm._v(" SHIR ")])]),_c('div',{staticClass:"content",class:{ open: _vm.isOpen }},[(_vm.showPipelines)?_c('integration-log',{attrs:{"listagem":_vm.pipelineList},on:{"redirect":_vm.redirect}}):_c('service-log',{attrs:{"listagem":_vm.shirList},on:{"redirect":_vm.redirect}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }