<template>
  <div id="cardStatus">
    <div
      class="status"
      :class="{ active: isActive, stopped: !isActive }"
    >
      <StatusIcon />
      <div class="text add-ellipsis">
        {{ isActive ? $t('Dashboard.Execution.Running') : $t('Dashboard.Execution.Stopped') }}
      </div>
    </div>
    <div class="info add-ellipsis">
      <div class="device">
        <div class="title">{{ $t('Dashboard.Status.Device') }}</div>
        <div class="d-flex">
          <div
            class="content add-ellipsis"
            id="device"
          >
            {{ device }}
          </div>
        </div>
        <b-tooltip
          custom-class="tooltip-date-device"
          target="device"
          triggers="hover"
          positioning="bottom"
          placement="bottom"
          boundary-padding="0"
        >
          {{ device }}
        </b-tooltip>
      </div>
      <div class="lastContact add-ellipsis">
        <div class="title">{{ $t('Dashboard.Status.LastContact') }}</div>
        <div class="d-flex">
          <div
            class="content add-ellipsis"
            id="lastContact"
          >
            {{ getFormatedDate(lastContact) }}
          </div>
        </div>
        <b-tooltip
          custom-class="tooltip-date-device"
          target="lastContact"
          triggers="hover"
          positioning="bottom"
          placement="bottom"
          boundary-padding="0"
        >
          {{ getFormatedDate(lastContact) }}
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
  import { BTooltip } from 'bootstrap-vue';
  import moment from 'moment';

  import StatusIcon from '@/assets/images/icons/status-icon-4.svg';

  export default {
    name: 'CardStatus',
    props: {
      active: {
        type: String
      },
      device: {
        type: String,
        default: ''
      },
      lastContact: {
        type: String,
        default: ''
      }
    },
    components: {
      StatusIcon,
      BTooltip
    },
    data() {
      return {
        date: '',
        isActive: false
      };
    },
    updated() {
      this.isActive = this.active == '1' ? true : false;
    },
    methods: {
      getFormatedDate(date) {
        if (!date) return '';

        const language = localStorage.getItem('language');
        if (language == 'pt') return moment(date).format('DD-MM-YYYY HH:mm');

        return moment(date).format('MM-DD-YYYY HH:mm');
      }
    }
  };
</script>

<style lang="scss">
  .tooltip-date-device {
    .tooltip-inner {
      color: #fff;
      max-width: 516px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 8px;
      background-color: #362f2b;
      text-align: left;
    }
  }

  @media (max-width: 768px) and (min-width: 480px) {
    .tooltip-date-device {
      .tooltip-inner {
        max-width: 425px;
      }
    }
  }

  @media (max-width: 480px) {
    .tooltip-date-device {
      .tooltip-inner {
        max-width: 288px;
      }
    }
  }
</style>
<style lang="scss" scoped>
  #cardStatus {
    display: flex;
    gap: 24px;
    padding: 16px;
    border-radius: 6px;
    background: #fff;
    .add-ellipsis {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .status {
      width: 50%;
      min-width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 8px;
      padding: 8px 16px;

      &.stopped {
        background-color: #ffebee;
        color: #b71c1c;
        svg {
          fill: #b71c1c !important;
        }
      }

      &.active {
        background-color: #e8f5e9;
        color: #1b5e20;
        svg {
          fill: #1b5e20 !important;
        }
      }

      svg {
        width: 100%;
        max-width: 20px;
      }

      .text {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .info {
      display: flex;
      gap: 16px;
      width: 50%;
      .device,
      .lastContact {
        min-width: calc(50% - (16px / 2));
        max-width: calc(50% - (16px / 2));
      }

      .device,
      .lastContact {
        .title {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #998f8a;
        }
        .content {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #4c4541;
        }
      }
    }
  }

  @media (max-width: 672px) {
    #cardStatus {
      flex-direction: column;
      gap: 12px;

      .status {
        width: 100%;
        max-width: none;
        min-width: 0;
      }

      .status .text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
      }
      .info {
        width: 100%;
        flex-direction: column;
        gap: 4px;

        .device,
        .lastContact {
          width: 100%;
          max-width: none;
          min-width: 0;
        }
      }
    }
  }
</style>
