<template>
  <div class="dashboard-page">
    <b-row>
      <b-col lg="12">
        <Header @filter="setFilter" />
      </b-col>
    </b-row>

    <div :class="['dashboard-content', { open: isOpen }]">
      <!-- isOpen || device !== 'desktop' -->
      <CardStatus
        class="card-status"
        :active="dashboardData.active"
        :device="dashboardData.device"
        :lastContact="dashboardData.last_contact"
      />

      <div class="info-cards-container">
        <CardExecution
          class="card-execution tiny-card"
          :time="dashboardData.time"
          :total="dashboardData.total"
        />

        <CardSuccess
          class="card-success tiny-card"
          :total="dashboardData.success"
          :entry="dashboardData.dashboard.success"
          :isResizing="isResizing"
        />

        <CardFailure
          class="card-failure tiny-card"
          :total="dashboardData.error"
          :entry="dashboardData.dashboard.error"
          :isResizing="isResizing"
        />
      </div>

      <CardLog
        class="card-log-c"
        :showPipelines="showPipelines"
        :shirList="shirList"
        :pipelineList="pipelineList"
        :filter="filter"
        :isOpen="isOpen"
        @showPipelines="handleShowPipelines"
        @fetchPipeline="getPipeline"
        @fetchShir="getShir"
      />

      <CardGraph
        class="card-graph"
        :entry="dashboardData.dashboard"
        :oneDayRange="oneDayRange"
        :isResizing="isResizing"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { BCol, BRow } from 'bootstrap-vue';
  import Header from './components/header';
  import CardStatus from './components/card-status/index.vue';
  import CardExecution from './components/card-execution/index.vue';
  import CardFailure from './components/card-failure/index.vue';
  import CardSuccess from './components/card-success/index.vue';
  import CardGraph from './components/card-graph-execution/index.vue';
  import CardLog from './components/card-logs/LastLogs.vue';
  import moment from 'moment';

  export default {
    components: {
      BCol,
      BRow,
      Header,
      CardStatus,
      CardExecution,
      CardFailure,
      CardSuccess,
      CardGraph,
      CardLog
    },
    data() {
      return {
        isOpen: false,
        isResizing: false,
        dashboardData: {
          active: '0',
          device: '',
          last_contact: '',
          time: '',
          total: '',
          success: '0',
          error: '',
          dashboard: {
            error: [],
            success: []
          }
        },
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        isMobile: window.innerWidth <= 768,
        device:
          window.innerWidth > 1024
            ? 'desktop'
            : window.innerWidth > 425 && window.innerWidth <= 1024
            ? 'tablet'
            : 'mobile',
        filter: {
          allPeriod: 0,
          startDate: '',
          endDate: '',
          days: 1
        },
        oneDayRange: true,
        showPipelines: true,
        shirList: [],
        pipelineList: []
      };
    },
    mounted() {
      this.getDashboardData();
      this.getPipeline();
      this.createSidebarObserver();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      handleShowPipelines(value) {
        this.showPipelines = value;
      },
      createSidebarObserver() {
        const sidebar = document.getElementById('menu-bar');

        if (!sidebar) return;

        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.attributeName === 'class') {
              this.isResizing = true;
              this.isOpen = sidebar.classList.contains('open');
              setTimeout(() => {
                this.isResizing = false;
              }, 600);
            }
          }
        });

        observer.observe(sidebar, { attributes: true });
      },
      clearFilter() {
        this.filter = {
          allPeriod: 0,
          startDate: '',
          endDate: '',
          days: 0
        };
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      setFilter({ option, datepicker }) {
        this.clearFilter();
        let oneDayFlag = false;

        if (option !== null) {
          this.filter.allPeriod = option === 'all' ? 1 : 0;
          this.filter.days = option !== 'all' ? option : 0;
          oneDayFlag = option == 1;
        } else {
          this.filter.startDate = moment(new Date(datepicker[0])).format('YYYY-MM-DD');
          this.filter.endDate = moment(new Date(datepicker[1])).format('YYYY-MM-DD');
        }

        this.oneDayRange = oneDayFlag;

        this.getDashboardData();
        this.showPipelines ? this.getPipeline() : this.getShir();
      },
      getDashboardData() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/mydashboard?filterDays=${this.filter.days}&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}&site_guid=${this.currentSite.guid}&allPeriod=${this.filter.allPeriod}`
          )
          .then((res) => {
            this.dashboardData = res.data;
            this.refreshing = false;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getShir() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `api/${this.$router.currentRoute.params.prefix}/service-log?site_guid=${this.currentSite.guid}&filterDays=${this.filter.days}&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}&allPeriod=${this.filter.allPeriod}`
          )
          .then((res) => {
            this.shirList = res.data.response;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getPipeline() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/log?site_guid=${this.currentSite.guid}&filterDays=${this.filter.days}&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}&allPeriod=${this.filter.allPeriod}`
          )
          .then((res) => {
            this.pipelineList = res.data;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dashboard-page {
    transition: none;

    &.open {
      width: calc(100% - 5px);
    }

    .card-status {
      grid-area: status;
    }

    .info-cards-container {
      grid-area: infos;
      display: flex;
      gap: 24px;

      .card-execution,
      .card-success,
      .card-failure {
        min-width: calc((100% - 24px - 24px) / 3);
      }
    }

    .card-log-c {
      grid-area: log;
    }
    .card-execution {
      grid-area: execution;
    }
    .card-success {
      grid-area: success;
    }
    .card-failure {
      grid-area: failure;
    }
    .card-graph {
      grid-area: graph;
    }

    .dashboard-content {
      display: grid;
      gap: 24px 30px;
      grid-template:
        'status log'
        'infos log'
        'graph log'
        / minmax(74.31%, 74.31%) minmax(22.85%, 22.85%);
    }
  }

  @media (max-width: 1215px) and (min-width: 672px) {
    .dashboard-page {
      .dashboard-content {
        grid-template:
          'status status'
          'infos log'
          'graph graph'
          / minmax(56.96%, 56.96%) minmax(39.68%, 39.68%);
      }

      .info-cards-container {
        flex-direction: column;
        width: 100%;
      }
    }
  }

  @media (max-width: 1866px) and (min-width: 901px) {
    .dashboard-page {
      .dashboard-content {
        &.open {
          grid-template:
            'status status'
            'infos log'
            'graph graph'
            / minmax(56.96%, 56.96%) minmax(39.68%, 39.68%) !important;

          .info-cards-container {
            flex-direction: column !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .dashboard-page {
      .dashboard-content {
        &.open {
          grid-template:
            'status'
            'infos'
            'log'
            'graph'
            / minmax(100%, 100%) minmax(100%, 100%) !important;

          .info-cards-container {
            flex-direction: column !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: 672px) {
    .dashboard-page {
      .dashboard-content {
        grid-template:
          'status'
          'infos'
          'log'
          'graph'
          / minmax(100%, 100%) minmax(100%, 100%);

        .info-cards-container {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
</style>
