<template>
  <div id="cardFailure">
    <div class="header">
      <div class="textBlock">
        <div class="fail">
          {{ $t('Dashboard.Failure.Fail') }}
        </div>
        <imgTooltipIcon id="failureTooltip" />
        <b-tooltip
          custom-class="tooltip-dashboard"
          target="failureTooltip"
          triggers="hover"
          placement="bottomright"
        >
          <div>
            {{ $t('Dashboard.Failure.Tooltip') }}
          </div>
        </b-tooltip>
      </div>
      <div class="quantity">{{ total }}</div>
    </div>
    <div :class="['content', isResizing ? 'loading-card' : '']">
      <Spinner
        v-if="isResizing"
        class="spinner_dashboard"
      />
      <apexcharts
        v-else
        width="100%"
        height="61"
        type="area"
        :options="chartOptions"
        :series="series"
      ></apexcharts>
    </div>
  </div>
</template>

<script>
  import { BTooltip } from 'bootstrap-vue';
  import imgTooltipIcon from '@/assets/images/pages/tooltip-icon.svg';
  import VueApexCharts from 'vue-apexcharts';

  import Spinner from '@/assets/images/pages/load-icon.svg';

  export default {
    name: 'CardFailure',
    components: { BTooltip, imgTooltipIcon, apexcharts: VueApexCharts, Spinner },
    props: {
      isResizing: {
        type: Boolean
      },
      total: {
        type: String,
        default: ''
      },
      entry: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    methods: {
      updateEntries() {
        this.series = [
          {
            name: 'Fail',
            data: this.entry.map((item) => {
              return parseInt(item.quantity);
            })
          }
        ];
      }
    },
    mounted() {
      this.updateEntries();
    },
    data() {
      return {
        chartOptions: {
          chart: {
            id: 'failure-area',
            sparkline: {
              enabled: true
            },
            width: '100%'
          },
          tooltip: {
            enabled: false
          },
          fill: {
            colors: ['#FD444E'],
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0
            }
          },
          stroke: {
            colors: ['#EF5350'],
            curve: 'straight'
          }
        },
        series: []
      };
    },
    watch: {
      entry() {
        this.updateEntries();
      }
    }
  };
</script>
<style lang="scss" scoped>
  #cardFailure {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    max-width: 100%;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
    .header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      .textBlock {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 25px;

        svg {
          transform: scale(0.875);
        }

        .fail {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: #4c4541;
        }
      }
      .quantity {
        height: 25px;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 31px;
        color: #4c4541;
      }
    }
  }

  @media (max-width: 480px) {
    #cardFailure {
      max-width: 100%;
    }
    .tooltip-dashboard {
      margin-left: 25px;
    }
  }
</style>
