<template>
  <div class="dsb__header-c">
    <Breadcrumb />
    <PeriodFilterComponent
      id="period-filter-dashboard"
      ref="periodFilter"
      position="left"
      :options="periodFilterOptions"
      :defaultDate="periodFilterDefaultDate"
      @change="(value) => $emit('filter', value)"
    />
  </div>
</template>

<script>
  import Breadcrumb from '@/@core/components/breadcrumb';
  import PeriodFilterComponent from '@/@core/components/period-filter-component';

  export default {
    name: 'DashboardHeader',
    components: {
      Breadcrumb,
      PeriodFilterComponent
    },
    data: () => ({
      periodFilterOptions: [
        { value: '1', text: 'PeriodFilter.1day' },
        { value: '5', text: 'PeriodFilter.5days' },
        { value: '15', text: 'PeriodFilter.15days' },
        { value: null, text: 'PeriodFilter.Custom' },
        { value: 'all', text: 'PeriodFilter.AllHistoric' }
      ],
      periodFilterDefaultDate: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]
    })
  };
</script>

<style lang="scss">
  #period-filter-dashboard {
    .fld__dropdown-button {
      background: transparent !important;
    }
  }
</style>

<style lang="scss" scoped>
  .dsb__header-c {
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;

    .breadcrumb-slash {
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 485px) {
    .dsb__header-c {
      flex-direction: column;
      margin-bottom: 24px;
      gap: 24px;
    }
  }
</style>
