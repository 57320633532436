<template>
  <div :class="['log-section', { open: isOpen }]">
    <div class="card-log">
      <div class="graph-buttons-resources">
        <button
          type="button"
          :class="['btn-graph-resources', showPipelines ? 'btn-actual' : 'btn-disabled']"
          @click="
            () => {
              this.$emit('showPipelines', true);
              this.$emit('fetchPipeline');
            }
          "
        >
          Pipeline
        </button>

        <button
          type="button"
          :class="['btn-graph-resources', showPipelines ? 'btn-disabled' : 'btn-actual']"
          @click="
            () => {
              this.$emit('showPipelines', false);
              this.$emit('fetchShir');
            }
          "
        >
          SHIR
        </button>
      </div>
      <div
        class="content"
        :class="{ open: isOpen }"
      >
        <integration-log
          v-if="showPipelines"
          :listagem="pipelineList"
          @redirect="redirect"
        />
        <service-log
          v-else
          :listagem="shirList"
          @redirect="redirect"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ServiceLog from './ShirLastLogs.vue';
  import IntegrationLog from './PipelineLastLogs.vue';
  import { mapMutations } from 'vuex';

  export default {
    components: {
      ServiceLog,
      IntegrationLog
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
      showPipelines: {
        type: Boolean,
        default: true
      },
      filter: {
        type: Object,
        default() {
          return {
            allPeriod: 0,
            startDate: '',
            endDate: '',
            days: 1
          };
        }
      },
      shirList: {
        type: Array,
        default() {
          return [];
        }
      },
      pipelineList: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        selectedFilter: '1',
        selectedText: 'LogCenter.1Day',
        settings: '',
        filterDate: '',
        intervalFunction: 0,
        prefix: this.$router.currentRoute.params.prefix
      };
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      redirect(value) {
        if (this.$route.name === value.page) return;
        const user = JSON.parse(localStorage.getItem('userData'));
        let tenant = user.email.split('@');
        this.prefix = this.prefix ? this.prefix : tenant[1].split('.')[0];

        this.$router.push({
          name: value.page,
          query: {
            filterDays: this.filter.days,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            allPeriod: this.filter.allPeriod,
            id: value.id,
            shir: value.shir
          },
          params: { prefix: this.prefix }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .log-section {
    width: 100%;
    padding: 16px 0 1px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    border-radius: 6px;
    .card-log {
      width: 100%;
      .graph-buttons-resources {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .btn-graph-resources {
          font-size: 16px;
          height: 26px;
          border: none;
        }

        .btn-actual {
          border-bottom: 1px solid #974900;
          font-weight: 600;
          width: 100%;
          background-color: transparent !important;
          color: #974900 !important;
          border-radius: 0;
        }

        .btn-disabled {
          border-bottom: 1px solid #cfc4be;
          width: 100%;
          background-color: transparent !important;
          color: #cfc4be !important;
          border-radius: 0;
        }
      }
      .content {
        padding: 0 16px;
        overflow-y: auto;
        max-height: 541px;

        &::-webkit-scrollbar {
          width: 5px; /* width of the entire scrollbar */
          border-radius: 50%;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fbeee8; /* color of the tracking area */
          margin-bottom: 16px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
        }
      }
    }
  }

  @media (max-width: 1215px) {
    .log-section {
      .card-log {
        .content {
          max-height: 346px;
          min-height: 346px;
        }
      }
    }
  }

  @media (max-width: 1866px) and (min-width: 1215px) {
    .log-section {
      &.open {
        .card-log {
          .content {
            max-height: 346px !important;
            min-height: 346px !important;
          }
        }
      }
    }
  }
</style>
