<template>
  <div class="calendar">
    <div class="grid calendar-week-days">
      <span v-for="item in weekDays">{{ item.short }}</span>
    </div>
    <div class="grid calendar-days" ref="calendar">
      <button
        class="calendar-day-button"
        :class="{
          today: item.date.isSame(today, 'day'),
          highlighted:
            (!range &&
              selectedDay == item.date.date() &&
              selectedMonth == item.date.month() &&
              selectedYear == item.date.year()) ||
            (range && (item.date.isSame(firstDate, 'day') || item.date.isSame(lastDate, 'day'))),
          range: range && item.date > firstDate && item.date < lastDate,
          current: item.currentMonth,
          disabled: disabled || item.disabled,
          blocked: blockDates(item.date)
        }"
        v-for="item in generateCalendar()"
        :key="`${item.date.date()}-${item.date.month()}`"
        @click="changeDay(item)"
        :disabled="disabledDates(item.date, item.disabled)"
      >
        {{ item.date.date() }}
      </button>
    </div>
  </div>
</template>
<script>
  import dayjs from 'dayjs';

  export default {
    props: [
      'date',
      'weekDays',
      'disabled',
      'blockDates',
      'showDates',
      'range',
      'showPicker',
      'rangeDate'
    ],
    data() {
      return {
        today: new Date(),
        selectedMonth: this.date.getMonth(),
        selectedYear: this.date.getFullYear(),
        selectedDay: this.date.getDate(),
        dayjs,
        firstDate: null,
        lastDate: null,
        localRange: []
      };
    },
    mounted() {
      this.generateCalendar();
      this.addDraggable();
      this.initValues();
    },
    computed: {},
    methods: {
      disabledDates(date, disabled) {
        return this.disabled ? true : disabled ? true : this.blockDates(date);
      },
      changeSelectedMonth(direction) {
        var month = this.selectedMonth;
        var year = this.selectedYear;
        if (direction == 'prev') {
          if (month - 1 == -1) {
            month = 11;
            year -= 1;
          } else {
            month -= 1;
          }
        } else {
          if (month + 1 == 12) {
            month = 0;
            year += 1;
          } else {
            month += 1;
          }
        }

        this.$emit('changeDate', {
          day: this.range ? new Date().getDate() : this.selectedDay,
          month,
          year
        });
      },
      changeDay(value) {
        let day = value.date.date();
        let month = value.date.month();
        let year = value.date.year();
        if (this.range) {
          if (this.lastDate == null && !!this.firstDate) {
            if (value.date < this.firstDate) {
              this.lastDate = this.firstDate;
              this.firstDate = new Date(value.date);
            } else {
              this.lastDate = new Date(value.date);
            }

            this.localRange = [this.firstDate, this.lastDate];
          } else {
            this.lastDate = null;
            this.firstDate = new Date(value.date);
            this.localRange = [this.firstDate];
          }
          this.$emit('changeDate', {
            day,
            month,
            year
          });
          this.$emit('changeRange', this.localRange);
        } else {
          this.$emit('changeDate', {
            day,
            month,
            year
          });
        }
      },
      validateDate(date) {
        if (this.showDates.length) {
          if (date >= this.showDates[0] && date <= this.showDates[1]) return true;
          return false;
        } else {
          return true;
        }
      },
      generateCalendar(month = this.selectedMonth, year = this.selectedYear) {
        var days = [];

        const firstDateOfMonth = this.dayjs().year(year).month(month).startOf('month');

        const lastDateOfMonth = this.dayjs().year(year).month(month).endOf('month');

        // mês anterior
        for (let i = 0; i < firstDateOfMonth.day(); i++) {
          const date = firstDateOfMonth.day(i);
          let disabled = false;
          if (this.showDates.length && !this.validateDate(date)) {
            disabled = true;
          }
          days.push({
            currentMonth: false,
            date,
            disabled
          });
        }

        // mês atual
        for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
          let disabled = false;
          if (this.showDates.length && !this.validateDate(firstDateOfMonth.date(i))) {
            disabled = true;
          }
          days.push({
            currentMonth: true,
            date: firstDateOfMonth.date(i),
            disabled
          });
        }

        const remainingDif = 35 - days.length;

        const remaining = remainingDif < 0 ? 42 - days.length : remainingDif;

        // mês posterior
        for (let i = lastDateOfMonth.date() + 1; i <= lastDateOfMonth.date() + remaining; i++) {
          let disabled = false;
          if (this.showDates.length && !this.validateDate(lastDateOfMonth.date(i))) {
            disabled = true;
          }
          days.push({
            currentMonth: false,
            date: lastDateOfMonth.date(i),
            disabled
          });
        }

        return days;
      },
      addDraggable() {
        const calendarElement = this.$refs.calendar;

        let isDragging = false;
        let startX = 0;
        let scrollLeft = 0;
        let touchStartX = 0;

        calendarElement.addEventListener('mousedown', (e) => {
          isDragging = true;
          startX = e.pageX - calendarElement.offsetLeft;
          scrollLeft = calendarElement.scrollLeft;
        });

        calendarElement.addEventListener('mouseleave', (e) => {
          isDragging = false;
        });

        calendarElement.addEventListener('mouseup', (e) => {
          const x = e.pageX - calendarElement.offsetLeft;
          const walk = (x - startX) * 3; // Velocidade de arrasto

          if (walk > 300) {
            this.changeSelectedMonth('prev');
          } else if (walk < -300) {
            this.changeSelectedMonth('next');
          }
          isDragging = false;
        });

        calendarElement.addEventListener('mousemove', (e) => {
          if (!isDragging) return;
          e.preventDefault();
          const x = e.pageX - calendarElement.offsetLeft;
          const walk = (x - startX) * 3; // Velocidade de arrasto
          calendarElement.scrollLeft = scrollLeft - walk;
        });

        calendarElement.addEventListener('touchstart', (e) => {
          isDragging = true;
          const touch = e.touches[0];
          touchStartX = touch.pageX - calendarElement.offsetLeft;
          scrollLeft = calendarElement.scrollLeft;
        });

        calendarElement.addEventListener('touchmove', (e) => {
          if (!isDragging) return;
          e.preventDefault();
          const touch = e.touches[0];
          const x = touch.pageX - calendarElement.offsetLeft;
          const walk = (x - touchStartX) * 3; // Velocidade de arrasto
          calendarElement.scrollLeft = scrollLeft - walk;
        });

        calendarElement.addEventListener('touchend', (e) => {
          const touch = e.changedTouches[0];
          const x = touch.pageX - calendarElement.offsetLeft;
          const walk = (x - touchStartX) * 3; // Velocidade de arrasto

          if (walk > 300) {
            this.changeSelectedMonth('prev');
          } else if (walk < -300) {
            this.changeSelectedMonth('next');
          }
          isDragging = false;
        });
      },
      initValues() {
        if (this.range) {
          if (!this.rangeDate.length) {
            this.firstDate = null;
            this.lastDate = null;
            this.localRange = [];
          } else {
            this.firstDate = this.rangeDate[0] ?? null;
            this.lastDate = this.rangeDate[1] ?? null;
            this.localRange = this.rangeDate;
          }
        }
      }
    },
    watch: {
      date(value) {
        this.selectedMonth = value.getMonth();
        this.selectedYear = value.getFullYear();
        this.selectedDay = value.getDate();
      },
      showPicker() {
        this.initValues();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .calendar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .calendar-week-days {
      width: 100%;
      height: 32px;
      padding-block: 8px;
      color: #4c4541;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      font-weight: 400;
      background-color: #f7f3ef;
      border-bottom: 1px solid #cfc4be;
    }

    .calendar-days {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .calendar-day-button {
        height: 68px;
        outline: none;
        background-color: transparent;
        border: 1px solid transparent;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #cfc4be;

        &.current {
          color: #4c4541;
        }
        &.today {
          border-color: #974900;
          color: #974900;
        }

        &:hover:not(.highlighted) {
          background-color: #ffede2;
          color: #974900;
        }

        &.disabled,
        &.blocked {
          color: #cfc4be;
        }

        &.range {
          background-color: #ffede2;
          color: #974900;
        }

        &.highlighted {
          background-color: #974900;
          border-color: #974900;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .calendar {
      .calendar-week-days {
        height: 52px;
        padding-block: 16px;
        font-size: 18px;
        line-height: 26px;
      }

      .calendar-days {
        .calendar-day-button {
          font-size: 26px;
          line-height: 36px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 500px) {
    .calendar {
      .calendar-week-days {
        height: 28px;
        padding-block: 1px;
      }

      .calendar-days {
        .calendar-day-button {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .calendar {
      .calendar-week-days {
        height: 32px;
        font-size: 12px;
        line-height: 16px;
        padding-block: 8px;
      }

      .calendar-days {
        .calendar-day-button {
          font-size: 14px;
          line-height: 20px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .calendar {
      .calendar-week-days {
        height: 32px;
        font-size: 12px;
        line-height: 16px;
        padding-block: 8px;
      }

      .calendar-days {
        .calendar-day-button {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
</style>
