<template>
  <div id="integrationLogs">
    <div
      class="card-integration-log"
      v-for="(item, index) in listagemPipelines"
      :key="index"
      @click="() => redirect('log-center', item)"
    >
      <div :class="['status', item.status == 'Not Integrated' ? 'notIntegrated' : 'integrated']">
        {{ item.status }}
      </div>
      <div class="pipeline">
        <div class="label">{{ $t('Dashboard.Logs.Pipeline') }}</div>
        <div class="action --ellipsis">{{ item.pipeline }}</div>
      </div>
      <div class="activity">
        <div class="label">{{ $t('Dashboard.Logs.Activity') }}</div>
        <div class="action --ellipsis">{{ item.event_script }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        listagemPipelines: []
      };
    },
    props: {
      listagem: {
        type: Array
      }
    },
    watch: {
      listagem: function (newVal, oldVal) {
        this.listagemPipelines = newVal.slice(0, 5);
      }
    },
    methods: {
      redirect(page, item) {
        this.$emit('redirect', { page, id: item.id, shir: false });
      }
    }
  };
</script>

<style lang="scss">
  #integrationLogs {
    .card-integration-log {
      border-radius: 6px;
      border: 1px solid #eee;
      padding: 12px 8px;
      background: #fff;
      cursor: pointer;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 11px !important;
      }

      &:hover {
        background: #974900;
        .pipeline,
        .activity {
          .label,
          .action {
            color: #fff;
          }
        }
      }

      .--ellipsis {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        border-radius: 4px;
        &.notIntegrated {
          background-color: #ffebee;
          color: #f44336;
        }
        &.integrated {
          background-color: #e8f5e9;
          color: #4caf50;
        }
      }
      .pipeline {
        padding-top: 6px;
      }
      .activity {
        padding-top: 4px;
      }
      .pipeline,
      .activity {
        .label,
        .action {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #998f8a;
        }
        .action {
          font-weight: 600;
          color: #4c4541;
        }
      }
    }
  }
</style>
